import { Button, Icon, InputBase, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getSavedCarsAtom } from './atoms/savedCars';
import styles from './CreateCar.module.css';
import { getSvvCarAtom, regNumberAtom } from './atoms/svvInfo';
import { userHasOppkjoperRoleAtom } from './atoms/auth';
import { Search } from '@mui/icons-material';
import { useAtomValue, useSetAtom } from 'jotai';

const CreateCar = () => {
  const cars = useAtomValue(getSavedCarsAtom);
  const isOppkjoper = useAtomValue(userHasOppkjoperRoleAtom);
  const [regNumber, setRegNumber] = React.useState('');
  const [inputError, setInputError] = React.useState<string | undefined>(undefined);
  const regInputElement = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const setSVVInput = useSetAtom(regNumberAtom);

  const vehicleInfo = useAtomValue(getSvvCarAtom);

  useEffect(() => {
    // Vi må tømme staten første gang vi laster sida. Hvis ikke får vi all slags rare bugs.
    setSVVInput('');
  }, [setSVVInput]);

  useEffect(() => {
    if (vehicleInfo?.kind === 'success' && !!regNumber) {
      navigate(`/create/${regNumber}`);
    }
  }, [vehicleInfo, navigate, regNumber]);

  const fetchDataFromSVV = async (e: any) => {
    e.preventDefault();
    if (cars && cars.some((x) => x.regNumber === regNumber)) {
      setInputError('En bil med dette registreringsnummeret ligger allerede inne.');
      return;
    }

    if (!regNumber) {
      setInputError('Du må skrive inn et registreringsnummer.');
      return;
    }

    if (regNumber.length > 7 || regNumber.length < 2) {
      setInputError('Registreringsnummeret må være mellom 2 og 7 tegn.');
      return;
    }

    setInputError(undefined);
    setSVVInput(regNumber);
  };

  const svvIcon = (
    <Icon className={styles.svvIconContainer}>
      <img
        alt="svv-logo"
        src="https://res.cloudinary.com/hassel-software-as/image/upload/v1628535161/oppkjoper/statens-vegvesen-logo-header.svg"
        className={styles.svvIcon}
      />
    </Icon>
  );

  return (
    <Paper className={styles.formPaper}>
      <Typography variant="h2" className={styles.header}>
        {isOppkjoper ? 'Legg til oppkjøperbil' : 'Legg til bil'}
      </Typography>
      <form className={styles.firstForm} onSubmit={fetchDataFromSVV}>
        <Typography>Skriv inn registreringsnummer</Typography>
        <div className={styles.regNumberContainer}>
          <img
            className={styles.noPlateLeftSide}
            alt="regnummerdekorasjon"
            src="https://res.cloudinary.com/hassel-software-as/image/upload/v1628537080/oppkjoper/no-plate.svg"
          />
          <InputBase
            type="string"
            className={styles.licencePlateInput}
            value={regNumber}
            placeholder="AB 12345"
            inputRef={regInputElement}
            onChange={(e) => {
              setSVVInput('');
              setInputError('');
              setRegNumber(e.target.value.replaceAll(' ', '').toUpperCase());
            }}
            inputProps={{ 'aria-label': 'registreringsnummer' }}
          />
        </div>
        <Button
          variant="contained"
          color="secondary"
          className={styles.getInfoFromSVVButton}
          sx={{
            color: 'white',
            backgroundColor: '#484F55',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
          }}
          type="submit"
          size={'large'}
          endIcon={svvIcon}
          startIcon={<Search />}
        >
          Hent info
        </Button>
      </form>
      {inputError && <p className={styles.errorText}>{inputError}</p>}
      {vehicleInfo?.kind === 'error' && <p className={styles.errorText}>{vehicleInfo.error}</p>}
    </Paper>
  );
};

export default CreateCar;
