import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router';
import App from './App';
import './index.css';
import Loader from './Loader';
import { AuthenticationProvider } from './AuthenticationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import CreateCarPage from './pages/CreateCarPage';
import EditCarPage from './pages/EditCarPage';
import ManageUsersPage from './pages/ManageUsersPage';
import StatisticsPage from './StatisticsPage';
import CreateCar from './CreateCar';
import SavedCars from './SavedCars';

const container = document.getElementById('root');
const root = createRoot(container!);

const IndexPage = () => (
  <>
    <CreateCar />
    <div>
      <SavedCars />
    </div>
  </>
);

const RootRoute = () => (
  <AuthenticationProvider>
    <SnackbarProvider maxSnack={2}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="no">
        <App />
      </LocalizationProvider>
    </SnackbarProvider>
  </AuthenticationProvider>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootRoute />}>
      <Route index element={<IndexPage />} />
      <Route path="/create/:id" element={<CreateCarPage />} />
      <Route path="/edit/:id" element={<EditCarPage />} />
      <Route path="/brukere" element={<ManageUsersPage />} />
      <Route path="/statistikk" element={<StatisticsPage />} />
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loader fullscreen />}>
      <RouterProvider router={router} />
    </React.Suspense>
  </React.StrictMode>
);
