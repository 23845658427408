import React from 'react';
import {
  getAccountingInfoForCarWhenItIsSold,
  getAccountingInfoForOppkjoperCar,
  getAuctionEndDate,
  getCarAgeInDays,
  getLeasingAccountingInfoForCarWhenItIsSold,
  ListCar,
} from './types/Car';
import { auctionHostnameAtom, userHasOppkjoperRoleAtom } from './atoms/auth';
import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';
import styles from './SavedCars.module.css';
import { Typography } from '@mui/material';
import { AutoImage } from './CloudinaryImageComponents';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Launch } from '@mui/icons-material';
import { useAtomValue } from 'jotai';

export const CarCard: React.FC<{ car: ListCar }> = ({ car }) => {
  let valueWhenItIsSold, salesMargin;
  const isOppkjoper = useAtomValue(userHasOppkjoperRoleAtom);
  const auctionHost = useAtomValue(auctionHostnameAtom);

  const navigate = useNavigate();
  const numberFormat = Intl.NumberFormat('no-NO', {
    style: 'currency',
    currency: 'NOK',
    maximumSignificantDigits: 10,
  });

  if (isOppkjoper) {
    const accountingInfo = getAccountingInfoForOppkjoperCar(car);
    valueWhenItIsSold = accountingInfo.accountingValue ?? 0;
    salesMargin = accountingInfo.salesMargin;
  } else if (car.financialCategory === 'Egeneid') {
    const accountingInfo = getAccountingInfoForCarWhenItIsSold(car);
    valueWhenItIsSold = accountingInfo.accountingValue;
    salesMargin = accountingInfo.salesMargin;
  } else {
    const leasingAccountingInfo = getLeasingAccountingInfoForCarWhenItIsSold(car);
    valueWhenItIsSold = leasingAccountingInfo.totalPriceToBuyOutCar;
    salesMargin = leasingAccountingInfo.salesMarginLeasing;
  }

  const getDeliveryInfo = () => {
    const deliveryDate = DateTime.fromFormat(car.deliveryDate as string, 'yyyy-MM-dd').setLocale('no');
    return deliveryDate < DateTime.local() ? 'Leveringsklar' : 'Levering: ' + deliveryDate.toFormat('dd.MMM yyyy');
  };

  let modelcardCarCategoryBackground: string;
  switch (car.carCategory) {
    case 'Administrasjonsbil':
      modelcardCarCategoryBackground = 'linear-gradient(0deg, rgba(74,119,191,1) 0%, rgba(81,137,226,1) 100%)';
      break;
    case 'Utleiebil':
      modelcardCarCategoryBackground = 'linear-gradient(0deg, rgba(203,164,21,1) 0%, rgba(255,206,25,1) 100%)';
      break;
    case 'Demobil':
      modelcardCarCategoryBackground = 'linear-gradient(0deg, rgba(3,181,145,1) 0%, rgba(4,207,166,1) 100%)';
      break;
    default:
      modelcardCarCategoryBackground = 'transparent';
  }

  const auctionHasEnded = car.auctionEndDate && car.auctionEndDate < DateTime.local().toISO()!;

  return (
    <div key={car.vinNumber} className={styles.car}>
      <div className={styles.carCard} onClick={() => navigate(`/edit/${car.refId}`)}>
        {auctionHost && isOppkjoper && (
          <a
            href={`https://${auctionHost}/${car.vinNumber}`}
            target="_blank"
            rel="noreferrer"
            className={styles.auctionLink}
            title="Se bilen i auksjonsportalen (åpnes i ny fane)"
            onClick={(e) => e.stopPropagation()}
          >
            <Launch />
          </a>
        )}
        <div className={styles.carImageContainer}>
          <div className={styles.carCategoryBanner} style={{ background: modelcardCarCategoryBackground }}>
            <Typography>{car.carCategory}</Typography>
          </div>
          <AutoImage
            publicId={car.mainImage?.id}
            width={200}
            crop="limit"
            className={styles.carImage}
            alt={'Bilens hovedbilde'}
          />
          {car.regNumber && (
            <div
              className={
                styles.carRegNumberContainer + (car.carType?.includes('Varebil') ? ` ${styles.commercialVehicle}` : '')
              }
            >
              <img
                className={styles.noPlateLeftSide}
                alt="regnummerdekorasjon"
                src="https://res.cloudinary.com/hassel-software-as/image/upload/v1628537080/oppkjoper/no-plate.svg"
              />
              <Typography className={styles.regNumberText}>
                {car.regNumber.slice(0, 2) + ' ' + car.regNumber.slice(2)}
              </Typography>
            </div>
          )}
          <div className={styles.carLocationContainer}>
            <div className={styles.carLocation}>
              <LocationOnIcon style={{ fontSize: 14, marginRight: 2, marginBottom: 2 }} />
              <Typography>{car.location ?? 'Ukjent'}</Typography>
            </div>
          </div>
        </div>

        <div className={styles.carInfo}>
          <div className={styles.carTitleContainer}>
            <Typography className={styles.carTitle}>{car.title}</Typography>
          </div>

          <div className={styles.mileageContainer}>
            <Typography>
              {Intl.NumberFormat('no-NO', {
                style: 'decimal',
              }).format(car.mileage ?? 0)}{' '}
              km
            </Typography>
          </div>
          <div>
            {car?.isSold ? (
              <Typography className={styles.soldText}>Solgt</Typography>
            ) : (
              <Typography>
                {isOppkjoper ? 'Minstepris' : 'Pris:'} {numberFormat.format(car?.price ?? 0)}
              </Typography>
            )}
          </div>
          {car.highestBid?.bid && !car.isSold && (
            <div>
              <Typography>Høyeste bud: {numberFormat.format(car.highestBid.bid)}</Typography>
            </div>
          )}
          {isOppkjoper && !car.isSold && (
            <div className={auctionHasEnded ? styles.endedAuction : ''}>
              {auctionHasEnded ? (
                <Typography className={styles.auctionEndDate}>AUKSJON AVSLUTTET</Typography>
              ) : (
                <Typography className={styles.auctionEndDate}>
                  Frist: {getAuctionEndDate(car).toFormat('dd.MM kl. HH:mm')}
                </Typography>
              )}
            </div>
          )}
          {!!car.price && (car.contractPrice || car.pricePaidForOppkjoperCar) && (
            <div>
              <Typography className={salesMargin < 0 ? styles.negativeMargin : styles.positiveMargin}>
                Margin: {numberFormat.format(Math.round(salesMargin))} /{' '}
                {Math.abs((salesMargin / valueWhenItIsSold) * 100)
                  .toFixed(1)
                  .replace('.', ',')}{' '}
                %
              </Typography>
            </div>
          )}
          {!isOppkjoper && (
            <div className={styles.deliveryInfoContainer}>
              <Typography>Alder: {getCarAgeInDays(car)} dager</Typography>
              <Typography>{getDeliveryInfo()}</Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
