import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { getSavedCarAtomFamily, getSavedCarsAtom } from '../atoms/savedCars';
import { FormCar } from '../types/FormCar';
import { EditCarForm } from './EditCarForm';
import Loader from '../Loader';
import { saveCar } from './createCar';
import Prompt from '../Prompt';
import { useAtom, useSetAtom } from 'jotai';
import { getDateTime } from '../types/Car';
import _ from 'lodash';

const EditCarPage = () => {
  const params = useParams<{ id: string }>();
  const vinFromUrl = params.id;
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const [car, refreshCar] = useAtom(getSavedCarAtomFamily(vinFromUrl ?? ''));

  const navigate = useNavigate();
  const refreshCars = useSetAtom(getSavedCarsAtom);

  const { register, handleSubmit, control, watch, setValue, getValues, formState } = useForm<FormCar>({
    defaultValues: {
      model: car?.data.model,
      make: car?.data.make,
      title: car?.data.title,
      description: car?.data.description,
      modelYear: car?.data.modelYear.toString(),
      price: car?.data.price,
      buyNowPrice: car?.data.buyNowPrice,
      isSold: car?.data.isSold || null,
      finalPrice: car?.data.finalPrice || _.last(car?.data.bids)?.bid || undefined,
      contractPrice: car?.data.contractPrice,
      pricePaidForOppkjoperCar: car?.data.pricePaidForOppkjoperCar,
      vat: car?.data.vat,
      residualValue: car?.data.residualValue,
      leasingStartDate: getDateTime(car?.data.leasingStartDate),
      mileage: car?.data.mileage,
      carCategory: car?.data.carCategory,
      financialCategory: car?.data.financialCategory ?? 'Egeneid',
      deliveryDate: getDateTime(car?.data.deliveryDate),
      auctionEndDate: getDateTime(car?.data.auctionEndDate),
      regNumber: car?.data.regNumber,
      wheelDrive: car?.data.wheelDrive,
      adText: car?.data.adText,
      mainImageUrl: car?.data.mainImage?.url,
      mainImage: car?.data.mainImage ?? undefined,
      extraPhotoUrls: car?.data.extraPhotos.map((photo) => photo.url),
      extraPhotos: car?.data.extraPhotos ?? [],
      conditionReport: car?.data.conditionReport,
      equipmentList: car?.data.equipmentList ?? [],
      location: car?.data.location,
    },
  });

  const { errors } = formState;

  useEffect(() => {
    if (formState.isDirty && !hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }

    if (!formState.isDirty) {
      setHasUnsavedChanges(false);
    }

    if (formState.isSubmitSuccessful) {
      setHasUnsavedChanges(false);
      setTimeout(() => {
        refreshCar();
        refreshCars();
        navigate('/');
      }, 0);
    }
  }, [formState, hasUnsavedChanges, setHasUnsavedChanges, refreshCar, refreshCars, navigate]);

  if (!car) return <Loader />;

  const onSubmit = async (data: FormCar) => {
    const token = await getIdTokenClaims();
    data.title = getValues().title;
    await saveCar(car.ref['@ref'].id, data, token?.__raw || '', enqueueSnackbar);
  };

  const [model, make] = watch(['model', 'make']);

  if (make && model) {
    const title = `${make} ${model}`;
    setValue('title', title);
  }

  return (
    <>
      <Prompt hasUnsavedChanges={hasUnsavedChanges} />
      <EditCarForm
        register={register}
        carData={car.data}
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        watch={watch}
        setValue={setValue}
        isDirty={formState.isDirty}
      />
    </>
  );
};

export default EditCarPage;
