import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import styles from './app.module.css';
import { Link } from 'react-router';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import React, { ReactNode } from 'react';

export function TopBar({ children }: { children?: ReactNode }) {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  return (
    <AppBar position="static">
      <Toolbar>
        {children}
        <Typography className={styles.title} variant="h6">
          <Link to="/">Internbiler</Link>
        </Typography>
        {isAuthenticated ? (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ExitToAppIcon />}
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Logg ut
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<PersonIcon />}
            onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname } })}
          >
            Logg inn
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
