import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { DefaultEditor } from 'react-simple-wysiwyg';
import CarCategoryRadioButtonsGroup from '../CategorySelection';
import { AutoImage } from '../CloudinaryImageComponents';
import EquipmentList from '../Equipmentlist';
import { MultiPhotoPicker } from '../MultiPhotoPicker';
import { openConditionReportPicker, openMainPhotoPicker } from '../photoPicker';
import {
  Car,
  getAccountingInfoForWhenCarIsSold,
  getAccountingInfoToday,
  getDateTime,
  getLeasingAccountingInfo,
  getRegistrationCost,
  getRegistrationDate,
} from '../types/Car';

import styles from './EditCarForm.module.css';
import { DateTime } from 'luxon';
import FinancialCategorySelection from '../FinancialCategorySelection';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NumericFormat } from 'react-number-format';
import { dealershipLocationsAtom, userHasOppkjoperRoleAtom } from '../atoms/auth';
import { BidsTable } from '../BidsTable';
import _ from 'lodash';
import { FormCar } from '../types/FormCar';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';

export const EditCarForm = ({
  carData,
  onSubmit,
  control,
  register,
  errors,
  setValue,
  watch,
  isDirty,
}: {
  carData: Car;
  onSubmit: () => void;
  control: Control<FormCar>;
  register: UseFormRegister<FormCar>;
  errors: FieldErrors<FormCar>;
  setValue: UseFormSetValue<FormCar>;
  watch: UseFormWatch<FormCar>;
  isDirty: boolean;
}) => {
  const navigate = useNavigate();
  const isOppkjoper = useAtomValue(userHasOppkjoperRoleAtom);

  const mainImage = watch('mainImage', carData.mainImage);
  const conditionReport = watch('conditionReport', carData.conditionReport);
  const contractPrice = watch('contractPrice', carData.contractPrice);
  const vat = watch('vat', carData.vat);
  const financialCategory = watch('financialCategory', carData.financialCategory);
  const residualValue = watch('residualValue', carData.residualValue);
  const deliveryDate = watch('deliveryDate', carData.deliveryDate);
  const leasingStartDate = watch('leasingStartDate', carData.leasingStartDate ?? getRegistrationDate(carData));
  const price = watch('price', carData.price);
  const isSold = watch('isSold', carData.isSold);
  const userHasUploadedMainImage = !!mainImage;
  const userHasUploadedConditionReport = !!conditionReport;
  const locations = useAtomValue(dealershipLocationsAtom);

  const deliveryDateAsDateTime =
    deliveryDate instanceof DateTime
      ? deliveryDate
      : DateTime.fromFormat(deliveryDate || DateTime.local().toFormat('yyyy-MM-dd'), 'yyyy-MM-dd');
  const leasingStartDateAsDateTime =
    leasingStartDate instanceof DateTime
      ? leasingStartDate
      : !!leasingStartDate
        ? DateTime.fromFormat(leasingStartDate, 'yyyy-MM-dd')
        : undefined;

  const { accountingValue: accountingValueToday } = getAccountingInfoToday(carData, contractPrice ?? 0, price);
  const { accountingValue: accountingValueWhenCarIsSold, salesMargin } = getAccountingInfoForWhenCarIsSold(
    carData,
    contractPrice ?? 0,
    price,
    deliveryDateAsDateTime
  );

  const dateToUseForProjectedCalculations =
    deliveryDateAsDateTime > DateTime.local().plus({ months: 5 })
      ? deliveryDateAsDateTime
      : DateTime.local().plus({ months: 5 });

  const projectedCalculations = Array.from(Array(9).keys())
    .map((x, _, array) => dateToUseForProjectedCalculations.plus({ months: x - Math.floor(array.length / 2) }))
    .filter((dateTime) =>
      !!leasingStartDateAsDateTime ? dateTime.startOf('month') > leasingStartDateAsDateTime : false
    )
    .filter((dateTime) => dateTime.startOf('month') > DateTime.local())
    .map((sellingDate) =>
      getLeasingAccountingInfo(
        carData,
        price,
        contractPrice ?? 0,
        vat ?? 0,
        residualValue ?? 0,
        sellingDate,
        leasingStartDateAsDateTime ?? DateTime.local(),
        carData.carType === 'Varebil' ? 'Varebil' : 'Personbil'
      )
    );

  const registrationCost = getRegistrationCost(carData);

  const currencyNumberFormat = Intl.NumberFormat('no-NO', {
    style: 'currency',
    currency: 'NOK',
    maximumSignificantDigits: 10,
  });

  const monthlyPrice = projectedCalculations[0]?.monthlyPrice;

  const getOnValueChange = (onChange: (...event: any[]) => void) => (v: { value: string }) =>
    onChange(v.value !== '' ? Number(v.value) : '');

  return (
    <div className={styles.mainForm}>
      <Typography variant={'h1'} className={styles.header}>
        Rediger bil
      </Typography>
      <Paper className={styles.svvInfoPaper}>
        <div className={styles.logoContainer}>
          <img
            alt="svv-logo"
            src="https://res.cloudinary.com/hassel-software-as/image/upload/v1628535161/oppkjoper/statens-vegvesen-logo-header.svg"
            className={styles.svvIcon}
          />
        </div>
        <div className={styles.referenceContainer}>
          <div
            className={
              styles.licensePlate + (carData.carType?.includes('Varebil') ? ` ${styles.commercialVehicle}` : '')
            }
          >
            <img
              src="https://res.cloudinary.com/hassel-software-as/image/upload/v1628537080/oppkjoper/no-plate.svg"
              alt=""
            />
            <span className={styles.licencePlateText}>
              {`${carData.regNumber.slice(0, 2)} ${carData.regNumber.slice(2)}`}
            </span>
          </div>
          <div className={styles.chassisNumberHolder}>
            <div className={styles.chassisNumberPlate}>
              <img
                src="https://res.cloudinary.com/hassel-software-as/image/upload/v1628670809/demobil/screw.png"
                alt=""
              />
              <span>{carData.vinNumber}</span>
              <img
                src="https://res.cloudinary.com/hassel-software-as/image/upload/v1628670809/demobil/screw.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={styles.infoSection}>
          <div className={styles.infoContainer}>
            <Typography className={styles.infoTitle}>Farge</Typography>
            <Typography className={styles.infoBody}>{carData.color}</Typography>
          </div>
          <div className={styles.infoContainer}>
            <Typography className={styles.infoTitle}>Drivstoff</Typography>
            <Typography className={styles.infoBody}>{carData.fuelType}</Typography>
          </div>
          {carData.carType && (
            <div className={styles.infoContainer}>
              <Typography className={styles.infoTitle}>Biltype</Typography>
              <Typography className={styles.infoBody}>{carData.carType}</Typography>
            </div>
          )}
          <div className={styles.infoContainer}>
            <Typography className={styles.infoTitle}>Hjuldrift</Typography>
            <Typography className={styles.infoBody}>{carData.wheelDrive}</Typography>
          </div>{' '}
          <div className={styles.infoContainer}>
            <Typography className={styles.infoTitle}>Reg.dato</Typography>
            <Typography className={styles.infoBody}>
              {getRegistrationDate(carData).setLocale('no').toLocaleString(DateTime.DATE_FULL)}
            </Typography>
          </div>
        </div>
      </Paper>

      <form onSubmit={onSubmit} className={styles.form}>
        <Paper className={styles.paper}>
          <Typography className={styles.header} variant={'h2'}>
            Annonseinformasjon
          </Typography>
          {!isOppkjoper && (
            <Controller
              name="carCategory"
              control={control}
              defaultValue={carData.carCategory}
              render={({ field }) => (
                <CarCategoryRadioButtonsGroup
                  carCategory={field.value}
                  setCarCategory={field.onChange}
                  className={styles.carCategoryController}
                />
              )}
            />
          )}
          <div className={styles.inputFieldCollection}>
            <TextField
              {...register('modelYear', { required: true })}
              type="number"
              label="Årsmodell"
              variant="outlined"
              margin="normal"
              required
              className={styles.textField}
              defaultValue={carData.modelYear.toString()}
            />
            <TextField
              {...register('make', { required: true })}
              label="Merke"
              variant="outlined"
              margin="normal"
              className={styles.textField}
              defaultValue={carData.make}
              required
            />
            <TextField
              {...register('model', { required: true })}
              label="Modell"
              variant="outlined"
              margin="normal"
              className={styles.textField}
              defaultValue={carData.model}
              required
            />
            <TextField
              {...register('title', { required: true, maxLength: 115 })}
              label="Tittel"
              variant="outlined"
              margin="normal"
              helperText="Rediger Merke/Modell for å endre"
              className={styles.textField}
              disabled={true}
              defaultValue={carData.title}
            />
            {errors.title && errors.title.type === 'required' && (
              <span className={styles.errorText}>Bilen må ha en tittel</span>
            )}
            {errors.title && errors.title.type === 'maxLength' && (
              <span className={styles.errorText}>Tittelen kan ikke inneholde mer enn 115 tegn</span>
            )}
            <TextField
              {...register('description', { required: !isOppkjoper })}
              label="Undertittel"
              required={!isOppkjoper}
              variant="outlined"
              helperText="f.eks. '1.8 TDI med skinn og panoramatak'"
              margin="normal"
              className={styles.textField}
              defaultValue={carData.description}
            />
          </div>
          <div className={styles.inputFieldCollection}>
            {!isOppkjoper && (
              <Controller
                name="deliveryDate"
                control={control}
                defaultValue={getDateTime(carData.deliveryDate)}
                render={({ field }) => (
                  <DatePicker
                    defaultValue={field.value}
                    onChange={field.onChange}
                    className={styles.datePicker}
                    label="Bilen kan leveres"
                    slotProps={{
                      textField: {
                        helperText: 'Datoen kunden kan overta bilen',
                      },
                    }}
                  />
                )}
              />
            )}

            {isOppkjoper && (
              <Controller
                name="auctionEndDate"
                control={control}
                defaultValue={getDateTime(carData.auctionEndDate)}
                render={({ field }) => (
                  <DateTimePicker
                    defaultValue={field.value}
                    onChange={field.onChange}
                    className={styles.datePicker}
                    label="Budrunde avsluttes"
                    slotProps={{
                      textField: {
                        helperText: 'Tidspunkt auksjonen avsluttes',
                      },
                    }}
                    format="dd.MM.yyyy HH:mm"
                  />
                )}
              />
            )}

            <Controller
              name="mileage"
              rules={{ required: true }}
              render={({ field }) => (
                <NumericFormat
                  customInput={TextField}
                  allowNegative={false}
                  className={styles.textField}
                  onValueChange={getOnValueChange(field.onChange)}
                  thousandSeparator={' '}
                  required
                  variant="outlined"
                  label={isOppkjoper ? 'Kilometerstand' : 'Estimert km.stand ved levering'}
                  helperText={isOppkjoper ? '' : 'OBS: Ikke dagens km.stand'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="start">km</InputAdornment>,
                    },
                  }}
                  margin="normal"
                  value={field.value}
                  inputRef={field.ref}
                />
              )}
              control={control}
              defaultValue={carData.mileage}
            />

            {locations.length <= 1 ? (
              <input type="hidden" value={locations[0] ?? ''} {...register('location')} />
            ) : (
              <Controller
                name="location"
                defaultValue={carData.location}
                control={control}
                render={({ field }) => (
                  <FormControl className={styles.textField} variant="outlined" required={true} margin="normal">
                    <InputLabel id="location-label">Lokasjon</InputLabel>
                    <Select
                      label="Lokasjon"
                      labelId="location-label"
                      value={field.value}
                      onChange={field.onChange}
                      variant={'outlined'}
                    >
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location} value={location}>
                            {location}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            <Controller
              name="price"
              rules={{ required: true }}
              render={({ field }) => (
                <NumericFormat
                  customInput={TextField}
                  allowNegative={false}
                  className={styles.textField}
                  onValueChange={getOnValueChange(field.onChange)}
                  thousandSeparator={' '}
                  variant="outlined"
                  label={isOppkjoper ? 'Minstepris' : 'Pris'}
                  helperText={isOppkjoper ? 'Laveste pris dere er villige til å selge for' : 'Beløpet kunden må betale'}
                  required
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="start">kr</InputAdornment>,
                    },
                  }}
                  margin="normal"
                  value={field.value}
                  inputRef={field.ref}
                />
              )}
              control={control}
              defaultValue={carData.price}
            />
            {isOppkjoper && (
              <Controller
                name="buyNowPrice"
                render={({ field }) => (
                  <NumericFormat
                    customInput={TextField}
                    allowNegative={false}
                    className={styles.textField}
                    onValueChange={getOnValueChange(field.onChange)}
                    thousandSeparator={' '}
                    variant="outlined"
                    label={'"Kjøp nå"-pris'}
                    helperText="Pris dere selger bilen øyeblikkelig for"
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="start">kr</InputAdornment>,
                      },
                    }}
                    margin="normal"
                    value={field.value}
                    inputRef={field.ref}
                  />
                )}
                control={control}
                defaultValue={carData.buyNowPrice}
              />
            )}
            <Controller
              name="isSold"
              control={control}
              defaultValue={carData.isSold || null}
              render={({ field }) => (
                <FormControlLabel
                  className={styles.isSoldCheckbox}
                  control={
                    <Checkbox
                      checked={!!field.value}
                      onChange={() => field.onChange(!!field.value ? null : DateTime.local())}
                      color="primary"
                    />
                  }
                  label="Bilen er solgt"
                />
              )}
            />
            {!!isSold && isOppkjoper && (
              <Controller
                name="finalPrice"
                rules={{ required: true }}
                render={({ field }) => (
                  <NumericFormat
                    customInput={TextField}
                    allowNegative={false}
                    className={styles.textField}
                    onValueChange={getOnValueChange(field.onChange)}
                    thousandSeparator={' '}
                    variant="outlined"
                    label="Oppnådd pris"
                    helperText="Beløpet bilen faktisk ble solgt for"
                    required
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="start">kr</InputAdornment>,
                      },
                    }}
                    margin="normal"
                    value={field.value}
                    inputRef={field.ref}
                  />
                )}
                control={control}
                defaultValue={carData.finalPrice || _.last(carData.bids)?.bid || undefined}
              />
            )}
          </div>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h5'}>{isOppkjoper ? 'Høydepunkter' : 'Utstyr'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="equipmentList"
                control={control}
                defaultValue={carData.equipmentList ?? []}
                render={({ field }) => (
                  <EquipmentList includedEquipment={field.value} setIncludedEquipment={field.onChange} />
                )}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h5'}>Annonsetekst</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="adText"
                control={control}
                defaultValue={carData.adText}
                render={({ field }) => (
                  <DefaultEditor
                    value={field.value}
                    onChange={field.onChange}
                    className={styles.adText}
                    containerProps={{ style: { width: '100%', minHeight: '300px' } }}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h5'}>{'Bilder' + (isOppkjoper ? ' og tilstandsrapport' : '')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.imagesContainer}>
                <Typography variant={'h3'}>Hovedbilde</Typography>
                {userHasUploadedMainImage && (
                  <AutoImage
                    className={styles.image}
                    width={500}
                    crop="limit"
                    publicId={mainImage?.id}
                    alt="Bilde av bilen"
                  />
                )}

                <Controller
                  name="mainImage"
                  control={control}
                  defaultValue={mainImage ?? undefined}
                  render={({ field }) => (
                    <Button
                      onClick={() => openMainPhotoPicker(field.onChange)}
                      variant="outlined"
                      color="primary"
                      size="medium"
                      className={styles.uploadImageButton}
                    >
                      {userHasUploadedMainImage ? 'Bytt bilde' : 'Last opp bilde'}
                    </Button>
                  )}
                />

                <Controller
                  name="extraPhotos"
                  control={control}
                  defaultValue={carData.extraPhotos ?? []}
                  render={({ field }) => <MultiPhotoPicker onChange={field.onChange} defaultValue={field.value} />}
                />
                {isOppkjoper && (
                  <>
                    <Typography variant="h3" className={styles.conditionReportHeader}>
                      Tilstandsrapport (pdf)
                    </Typography>
                    {userHasUploadedConditionReport && (
                      <>
                        <AutoImage
                          className={styles.conditionReportImage}
                          width={300}
                          crop="limit"
                          publicId={conditionReport?.id}
                          alt="Bilde av tilstandsrapport"
                        />
                        <Typography>
                          Antall sider:{' '}
                          {conditionReport?.pages ??
                            'Ukjent. Last opp tilstandsrapport på nytt for å få med sideantall.'}
                        </Typography>
                      </>
                    )}
                    <div>
                      <Controller
                        name="conditionReport"
                        control={control}
                        defaultValue={conditionReport}
                        render={({ field }) => (
                          <Button
                            onClick={() => openConditionReportPicker(field.onChange)}
                            variant="outlined"
                            color="primary"
                            size="medium"
                            className={styles.uploadImageButton}
                          >
                            {userHasUploadedConditionReport ? 'Bytt tilstandsrapport' : 'Last opp tilstandsrapport'}
                          </Button>
                        )}
                      />
                      {userHasUploadedConditionReport && (
                        <Button
                          onClick={() => setValue('conditionReport', undefined)}
                          variant="outlined"
                          color="primary"
                          size="medium"
                          className={styles.deleteConditionReportButton}
                        >
                          Slett tilstandsrapport
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </Paper>
        <Paper className={styles.paper}>
          <Typography className={styles.header} variant={'h2'}>
            Interne felter
          </Typography>
          {!isOppkjoper && (
            <div className={styles.inputFieldCollection}>
              <Controller
                name="financialCategory"
                control={control}
                defaultValue={carData.financialCategory ?? 'Egeneid'}
                render={({ field }) => (
                  <FinancialCategorySelection financialCategory={field.value} setFinancialCategory={field.onChange} />
                )}
              />
            </div>
          )}

          <div className={styles.inputFieldCollection}>
            {!isOppkjoper && (
              <Controller
                name="contractPrice"
                rules={{ required: true }}
                render={({ field }) => (
                  <NumericFormat
                    customInput={TextField}
                    allowNegative={false}
                    className={styles.textField}
                    onValueChange={getOnValueChange(field.onChange)}
                    thousandSeparator={' '}
                    variant="outlined"
                    label="Intern kontraktspris"
                    helperText="Salgspris i kjøpskontrakten"
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="start">kr</InputAdornment>,
                      },
                    }}
                    margin="normal"
                    value={field.value}
                    required
                    inputRef={field.ref}
                  />
                )}
                control={control}
                defaultValue={carData.contractPrice}
              />
            )}

            {isOppkjoper && (
              <Controller
                name="pricePaidForOppkjoperCar"
                render={({ field }) => (
                  <NumericFormat
                    customInput={TextField}
                    allowNegative={false}
                    className={styles.textField}
                    onValueChange={getOnValueChange(field.onChange)}
                    thousandSeparator={' '}
                    variant="outlined"
                    label="Pris gitt for bilen"
                    helperText="Pris dere har betalt for bilen. Vises aldri til andre."
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="start">kr</InputAdornment>,
                      },
                    }}
                    margin="normal"
                    value={field.value}
                    inputRef={field.ref}
                  />
                )}
                control={control}
                defaultValue={carData.pricePaidForOppkjoperCar}
              />
            )}

            {financialCategory === 'Leaset' && (
              <>
                <Controller
                  name="vat"
                  render={({ field }) => (
                    <NumericFormat
                      customInput={TextField}
                      allowNegative={false}
                      className={styles.textField}
                      onValueChange={getOnValueChange(field.onChange)}
                      thousandSeparator={' '}
                      variant="outlined"
                      label="Mva"
                      helperText="Mva-beløp i kjøpskontrakten"
                      slotProps={{
                        input: {
                          endAdornment: <InputAdornment position="start">kr</InputAdornment>,
                        },
                      }}
                      margin="normal"
                      value={field.value}
                      inputRef={field.ref}
                    />
                  )}
                  control={control}
                  defaultValue={carData.vat}
                />

                <Controller
                  name="residualValue"
                  render={({ field }) => (
                    <NumericFormat
                      customInput={TextField}
                      allowNegative={false}
                      className={styles.textField}
                      onValueChange={getOnValueChange(field.onChange)}
                      thousandSeparator={' '}
                      variant="outlined"
                      label="Restverdi"
                      helperText="Restverdi i leasingkontrakten"
                      slotProps={{
                        input: {
                          endAdornment: <InputAdornment position="start">kr</InputAdornment>,
                        },
                      }}
                      margin="normal"
                      value={field.value}
                      inputRef={field.ref}
                    />
                  )}
                  control={control}
                  defaultValue={carData.residualValue}
                />

                <Controller
                  name="leasingStartDate"
                  control={control}
                  defaultValue={getDateTime(carData.leasingStartDate) ?? getRegistrationDate(carData)}
                  render={({ field }) => (
                    <DatePicker
                      defaultValue={field.value}
                      onChange={field.onChange}
                      className={styles.datePicker}
                      label="Leasingavtalens dato"
                      slotProps={{
                        textField: {
                          helperText: 'Leasingavtalens kontraktsdato',
                        },
                      }}
                    />
                  )}
                />
              </>
            )}
          </div>

          {!!contractPrice && price && financialCategory === 'Egeneid' && (
            <div className={styles.accountingValue}>
              <Typography variant="h5">Beregning (egeneid)</Typography>
              <div className={styles.calculationLine}>
                <Typography variant="caption">Intern kontraktspris</Typography>
                <Typography>{currencyNumberFormat.format(Math.round(contractPrice))}</Typography>
              </div>
              <div className={styles.calculationLine}>
                <Typography variant="caption">Avskrevet beløp</Typography>
                <Typography>{currencyNumberFormat.format(Math.round(contractPrice - accountingValueToday))}</Typography>
              </div>
              <div className={styles.calculationLine}>
                <Typography variant="caption">Bokført verdi i dag</Typography>
                <Typography>{currencyNumberFormat.format(Math.round(accountingValueToday))}</Typography>
              </div>
              <div className={styles.calculationLine}>
                <Typography variant="caption">
                  Bokført verdi {deliveryDateAsDateTime.setLocale('no').toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
                <Typography>{currencyNumberFormat.format(Math.round(accountingValueWhenCarIsSold))}</Typography>
              </div>
              <div className={styles.calculationLine}>
                <Typography variant="caption">Utsalgspris</Typography>
                <Typography>{currencyNumberFormat.format(Math.round(price))}</Typography>
              </div>
              <div className={styles.calculationLine}>
                <Typography variant="caption">Omregistrering</Typography>
                <Typography>{currencyNumberFormat.format(registrationCost)}</Typography>
              </div>
              <div className={styles.calculationLine}>
                <Typography variant="caption">Margin</Typography>
                <Typography className={salesMargin < 0 ? styles.negativeMargin : styles.positiveMargin}>
                  {currencyNumberFormat.format(Math.round(salesMargin))}
                </Typography>
              </div>
              <div className={styles.calculationLine}>
                <Typography variant="caption">Prosentmargin</Typography>
                <Typography className={salesMargin < 0 ? styles.negativeMargin : styles.positiveMargin}>
                  {((salesMargin / accountingValueWhenCarIsSold) * 100).toFixed(1)}%
                </Typography>
              </div>
            </div>
          )}

          {!!contractPrice && price && (vat ?? 0) >= 0 && financialCategory === 'Leaset' && (
            <div>
              <div className={styles.leasingAccountingValue}>
                <Typography variant="h5">Beregning</Typography>
                <div className={styles.calculationLine}>
                  <Typography variant="caption">Kontraktspris eks. mva.</Typography>
                  <Typography>
                    {currencyNumberFormat.format(Math.round(projectedCalculations[0]?.valueAtStartOfLeasing))}
                  </Typography>
                </div>

                <div className={styles.calculationLine}>
                  <Typography variant="caption">Månedsleie i leasingkontrakten</Typography>
                  <Typography>{currencyNumberFormat.format(Math.round(monthlyPrice))}</Typography>
                </div>
                <div className={styles.calculationLine}>
                  <Typography variant="caption">Omregistrering</Typography>
                  <Typography>{currencyNumberFormat.format(registrationCost)}</Typography>
                </div>
              </div>
              <div className={styles.buyOutCostTable}>
                <Typography variant="h6">Utkjøpsberegning</Typography>
                <TableContainer component={Paper}>
                  <Table className={styles.projectionTable}>
                    <TableHead>
                      <TableRow className={styles.tableHead}>
                        <TableCell>Måned</TableCell>
                        <TableCell>
                          Utkjøpspris
                          <br />
                          eks. mva.
                        </TableCell>
                        <TableCell>Mva-refusjon</TableCell>
                        <TableCell>Total ukjøpspris</TableCell>
                        <TableCell>
                          Diff. fra
                          <br />
                          forrige mnd.
                        </TableCell>
                        <TableCell>
                          Margin inkl. omreg.
                          <br />
                          ved salgspris
                          <br />
                          {currencyNumberFormat.format(Math.round(price))}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectedCalculations.map((calculation, index) => (
                        <TableRow
                          key={calculation.leasingEndDate.toString()}
                          className={classNames({
                            [styles.emphasizedRow]:
                              calculation.leasingEndDate.month === deliveryDateAsDateTime.month &&
                              calculation.leasingEndDate.year === deliveryDateAsDateTime.year,
                          })}
                        >
                          <TableCell>{calculation.leasingEndDate.setLocale('no').toFormat('MMMM yyyy')}</TableCell>
                          <TableCell>
                            {currencyNumberFormat.format(Math.round(calculation.presentValueOfCar))}
                          </TableCell>
                          <TableCell>{currencyNumberFormat.format(Math.round(calculation.vatToPayback))}</TableCell>
                          <TableCell>
                            {currencyNumberFormat.format(Math.round(calculation.totalPriceToBuyOutCar))}
                          </TableCell>
                          <TableCell>
                            {index === 0
                              ? '-'
                              : currencyNumberFormat.format(
                                  Math.round(
                                    projectedCalculations[index - 1].totalPriceToBuyOutCar -
                                      calculation.totalPriceToBuyOutCar
                                  )
                                )}
                          </TableCell>
                          <TableCell
                            className={
                              price - calculation.totalPriceToBuyOutCar < 0
                                ? styles.negativeMargin
                                : styles.positiveMargin
                            }
                          >
                            <span>
                              {currencyNumberFormat.format(Math.round(price - calculation.totalPriceToBuyOutCar))}{' '}
                            </span>
                            <span className={styles.marginPercentage}>
                              (
                              {(
                                ((price - calculation.totalPriceToBuyOutCar) / calculation.totalPriceToBuyOutCar) *
                                100
                              ).toFixed(1)}
                              %)
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
        </Paper>

        <div className={styles.buttonContainer}>
          <Button variant="contained" color="secondary" size="medium" onClick={() => navigate('/')} type="button">
            Avbryt
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            startIcon={<SaveIcon />}
            disabled={!isDirty}
          >
            Lagre
          </Button>
        </div>
      </form>
      {!!carData.bids && (
        <Paper className={classNames(styles.paper, styles.tableContainer)}>
          <Typography className={styles.header} variant={'h2'}>
            Budhistorikk
          </Typography>
          <BidsTable bids={carData.bids.slice().reverse()} />
        </Paper>
      )}
    </div>
  );
};
